import axios from "axios"
import _ from "lodash"
import util from "./util"

const ENDPOINT = 'https://apigateway.payfirma.com/merchant-service/hpp/init'
const DOMAIN = 'https://www.albertaforesthistory.ca'
const TOKEN = 'eyJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NfdG9rZW4iOiJmZmJiYTk5MC1mNmJlLTRkZGYtYmIxYy0wZDEwMDk1YTU4NDUiLCJzY29wZSI6WyJtZXJjaGFudCIsInRyYW5zYWN0aW9uIl0sIm1lcmNoYW50X2lkIjoiOWYyZDk1MmRjNyIsImV4cCI6MTk5NTg2NzY5Nn0.n3Mkrn9qEXVzj88is_vqZsahflTl-QgNGbjfNW7k-_0'
const header = {
    headers: {
        // HPP Token generated through the PayHQ Settings page
        Authorization: `Bearer ${TOKEN}`,
        'Content-Type': 'application/json'
    }
};


/**
 *
 * @param {*} membershipType send membership payment
 */
export const sendPayment = async membershipTypeUpdatedObj => {

    const billingAddress = membershipTypeUpdatedObj.billing_address
    const billingAddressString = `{"address1": "${billingAddress.address1}","address2": "${billingAddress.address2}", "city": "${billingAddress.city}","province": "${billingAddress.province}","state": "${billingAddress.state}","country": "${billingAddress.country}","postalCode": "${billingAddress.postalCode}","zipCode": "${billingAddress.zipCode}"}`

    const requestBody = {
        // Amount to be charged
        amount: membershipTypeUpdatedObj.amount,
        first_name: membershipTypeUpdatedObj.first_name,
        last_name: membershipTypeUpdatedObj.last_name,
        email: membershipTypeUpdatedObj.email,
        telephone: membershipTypeUpdatedObj.telephone,
        billing_address: billingAddressString,
        // CAD or USD
        currency: 'CAD',
        // Domain registered in PayHQ - Settings - Hosted Pay Page when you setup
        // ex) https://payfirma-hpp-demo.web.app
        domain: DOMAIN,
        // tax: membershipType.tax,
        // State that needs to be contained with redirect URL when the transaction is done
        state: 'stateIWantToKeep',
        // Field used to keep track of transactions and for future accounting reconciliation
        custom_id: 'Please enter our custom Id'
    }

    let description = "";
    const type = membershipTypeUpdatedObj.type;
    const duration = membershipTypeUpdatedObj.duration;
    const amount = membershipTypeUpdatedObj.amount;

    if (type === 'donation') {
        description = `Donation $${amount}`;
    } else {
        description = `${duration} year ${type} subscription with the donation of $${membershipTypeUpdatedObj.donationAmount}`;
    }

    // Add the description to the requestBody
    requestBody.description = description;
    console.log('Request Body with Description:', requestBody);
    console.log('Request Body:', requestBody)

    return new Promise(function (resolve, reject) {
        axios.post(ENDPOINT, requestBody, header).then(response => {
            // If Success
            if (response.status === 200) {
                // Users are linked to Payfirma Hosted Pay Page
                window.location.href = response.data.url;
            }
        });
        // axios.post("/locations", location).then(
        //   response => {
        //     console.log("location created successfully", response)
        //     resolve(util.fixUrls(response.data))
        //   },
        //   error => {
        //     console.log("createLocation error:", error)
        //     reject(error)
        //   }
        // )
    })
}

export default {
    sendPayment
}